export const ALLOCATIONS = {
    UNALLOCATED: '',
    CONTROL: '1',
    TEST_B: '2',
    TEST_C: '3',
    TEST_D: '4',
};

export const EXPERIMENTS = {
    PJM_BANNER: 4757,
};

export const ROLLOUTS = {
    AGENCIES_BANNERS_ROLLOUT: 'anacondas_agencies_banners',
    PROJECT_PARTNER_BANNER_ROLLOUT: 'zebras_project_partner_banner_in_vertical_page',
    PROJECT_PARTNER_BANNER_IN_MARKETPLACE_ROLLOUT: 'zebras_project_partner_banner_in_marketplace',
    MUSTANGS_PJM_BANNER_VERTICAL_PAGE: 'mustangs_pjm_banner_vertical_page',
} as const;

export const DEFAULT_NUM_OF_GROUPS = 2;
