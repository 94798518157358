import React, { ReactNode } from 'react';
import { throttle } from 'lodash';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { Card, Container, Stack, Wrap, WrapItem } from '@fiverr-private/layout_components';
import { Heading, Text, Typography } from '@fiverr-private/typography';
import { Button } from '@fiverr-private/button';
import { Image } from '@fiverr-private/media';
import { BQ_EVENTS, EVENT_TYPES } from '../../utils/reporter/constants';
import BigQuery from '../../utils/bigQuery';
import eventReporter from '../../utils/reporter';
import { AgenciesBannerTag } from './AgenciesBannerTag/AgenciesBannerTag';
import { AgenciesBannerInfo } from './AgenciesBanner.types';

export const AgenciesBanner: React.FC<{ agenciesBannerInfo: AgenciesBannerInfo }> = ({ agenciesBannerInfo }) => {
    const reportBannerClick = () => {
        eventReporter.reportEvent(
            EVENT_TYPES.CLICKED_AGENCIES_BANNER,
            eventReporter.mixpanelEventEnrichment(agenciesBannerInfo.verticalId, agenciesBannerInfo.verticalTitle, {})
        );

        BigQuery.sendBQEvent({
            type: BQ_EVENTS.USER_CLICKED_ON_DISCOVER_AGENCIES_BUTTON,
            pageName: agenciesBannerInfo.verticalTitle,
            group: 'user_actions',
            actionType: 'click',
            pageElementType: 'click',
        });
    };

    const reportBannerHover = () =>
        BigQuery.sendBQEvent({
            type: BQ_EVENTS.HOVER_ON_DISCOVER_AGENCIES_BANNER,
            pageName: agenciesBannerInfo.verticalTitle,
            group: 'user_actions',
            actionType: 'hover',
            pageElementType: 'hover',
        });

    const reportButtonHover = () =>
        BigQuery.sendBQEvent({
            type: BQ_EVENTS.HOVER_ON_DISCOVER_AGENCIES_BUTTON,
            pageName: agenciesBannerInfo.verticalTitle,
            group: 'user_actions',
            actionType: 'hover',
            pageElementType: 'hover',
        });

    const handleBannerHover = throttle(reportBannerHover, 1000);

    const headerTranslationTemplates = {
        italic: (text?: ReactNode) => (
            <Typography fontStyle="italic" fontFamily="domaine" fontWeight="normal" as="span">
                {text}
            </Typography>
        ),
    };

    return (
        <Card borderRadius="lg" onMouseOver={handleBannerHover}>
            <Stack
                direction={{
                    default: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                    xl: 'row',
                }}
                justifyContent="spaceBetween"
                alignItems={{
                    default: 'center',
                    sm: 'flexStart',
                    lg: 'center',
                    xl: 'center',
                }}
                margin={{
                    default: '6',
                    sm: '6',
                    md: '6',
                    lg: '0',
                    xl: '0',
                }}
                gap={{
                    default: '8',
                }}
            >
                <Stack
                    direction="column"
                    margin={{
                        default: '0',
                        lg: '8',
                        xl: '8',
                    }}
                    gap={{
                        default: '8',
                        lg: '6',
                        xl: '6',
                    }}
                >
                    <Stack direction="column" gap="2">
                        <Heading fontFamily="primary" fontWeight="semibold" as="h2">
                            <I18n
                                k="vertical_page_perseus.agencies_banner.header"
                                templates={headerTranslationTemplates}
                            />
                        </Heading>
                        <Text as="p" size="b_lg">
                            <I18n
                                k={`vertical_page_perseus.agencies_banner.description.${agenciesBannerInfo.descriptionKey}`}
                            />
                        </Text>
                    </Stack>
                    <Wrap direction="row" alignItems="center" gap="1.5">
                        {agenciesBannerInfo.tags.map((tag) => (
                            <AgenciesBannerTag
                                key={tag}
                                fontColor={agenciesBannerInfo.fontColor}
                                backgroundColor={agenciesBannerInfo.backgroundColor}
                                message={translate(`vertical_page_perseus.agencies_banner.tags.${tag}`)}
                            />
                        ))}
                        <WrapItem alignItems="center">
                            <Typography
                                whiteSpace="nowrap"
                                fontWeight="semibold"
                                as="p"
                                size="b_sm"
                                color={agenciesBannerInfo.fontColor}
                            >
                                <I18n k="vertical_page_perseus.agencies_banner.tags.and_more" />
                            </Typography>
                        </WrapItem>
                    </Wrap>

                    <Container>
                        <Button
                            onMouseOver={reportButtonHover}
                            href={agenciesBannerInfo.searchPath}
                            onClick={reportBannerClick}
                        >
                            <I18n k="vertical_page_perseus.agencies_banner.browse_agencies" />
                        </Button>
                    </Container>
                </Stack>
                <Stack
                    alignSelf={{
                        sm: 'center',
                    }}
                >
                    <Image
                        alt={
                            translate(
                                `vertical_page_perseus.agencies_banner.description.${agenciesBannerInfo.descriptionKey}`
                            ) as string
                        }
                        src={agenciesBannerInfo.bgImageUrl}
                        width={{ default: '100%', sm: 400, lg: 611 }}
                        height={{ sm: 170, lg: 260 }}
                        marginBottom={{ default: '0', sm: '7', lg: '0' }}
                    />
                </Stack>
            </Stack>
        </Card>
    );
};
