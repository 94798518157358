export const VERTICALS_ENTRY_ID = '6SS7vW2kKwD2shM7utkMiH';
export const BUCKETS_DESIGN_STYLE = 'Buckets with Title';
export const BUCKETS_SECOND_DESIGN_STYLE = 'Buckets without Title';
export const MOST_POPULAR_DESIGN_STYLE = 'Styled List Item';
export const FLP_TYPE = 'Filtered Listing';
export const UNKNOWN = 'unknown';
export const BUSINESS_PREFIX = 'business-';
export const FLP_URL_ID = 'buy';

export const PATHS = {
    ROOT: '#!',
    HOMEPAGE: '/',
};

export const TYPES = {
    VERTICAL: 'vertical',
    CATEGORY: 'category',
    SUB_CATEGORY: 'sub_category',
    NESTED_SUB_CATEGORY: 'nested_sub_category',
    CMS: 'CMS',
    SEARCH_QUERY: 'search_query',
    STATIC_PAGE: 'static_page',
    FILTERED_PAGE: 'filtered_page',
    PLACEHOLDER: 'placeholder',
    MENU_PANEL: 'menu_panel',
};

export const CATEGORIES_IDS = [
    'online-marketing',
    'graphics-design',
    'writing-translation',
    'lifestyle',
    'business',
    'programming-tech',
    'music-audio',
    'video-animation',
    'data',
    'photography',
    'end-to-end-projects',
    'finance',
];

export const CATEGORIES_ID_MAPPER = {
    'online-marketing': 2,
    'graphics-design': 3,
    'writing-translation': 5,
    lifestyle: 7,
    business: 8,
    'programming-tech': 10,
    'music-audio': 12,
    'video-animation': 20,
    data: 23,
    photography: 25,
    'end-to-end-projects': 28,
    finance: 29,
};

export const CATEGORIES_ID_MAPPER_REVERSE = Object.fromEntries(
    Object.entries(CATEGORIES_ID_MAPPER).map(([key, value]) => [value, key])
);

export const TOTAL_AVAILABLE_BANNERS = 2;

export const CMS_CONTENT_TYPES = {
    NAVIGATION_PAGE: 'navigationPage',
    VERTICAL_EXPERIENCE_PAGE: 'verticalExperiencePage',
};

export const AGENCIES_PROGRAMMING_BANNER_TAG_KEYS: string[] = [
    'mobile_app_development',
    'website_development',
    'software_development',
];

export const AGENCIES_DIGITAL_MARKETING_BANNER_TAG_KEYS: string[] = [
    'social_media_presence',
    'online_advertising',
    'b2b_marketing',
];

export const AGENCIES_GRAPHICS_DESIGN_BANNER_TAG_KEYS: string[] = [
    'visual_identity_branding',
    'web_app_design',
    'marketing_advertising',
];

export const PJM_BANNER_SUPPORTED_CATEGORIES = [
    'programming-tech',
    'online-marketing',
    'video-animation',
    'writing-translation',
    'graphics-design',
] as const;

export const PJM_BANNER_SUPPORTED_CATEGORY_IDS = PJM_BANNER_SUPPORTED_CATEGORIES.map(
    (category) => CATEGORIES_ID_MAPPER[category]
);
