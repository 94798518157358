import React, { useEffect } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { I18n } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';
import { Card, Container, Stack } from '@fiverr-private/layout_components';
import { Heading, Text } from '@fiverr-private/typography';
import { Button } from '@fiverr-private/button';
import { Image } from '@fiverr-private/media';
import { CheckIcon, GuaranteeIcon } from '@fiverr-private/visuals';
import { List, ListItem, ListItemIcon } from '@fiverr-private/list';
import { BQ_EVENTS } from '../../utils/reporter/constants';
import BigQuery from '../../utils/bigQuery';
import { CATEGORIES_ID_MAPPER } from '../../../lib/constants/verticalsData';
import { EXPERIMENTS } from '../../../lib/constants/experiments';
import { PJM_COPIES_PREFIX, PROJECT_PARTNER_IMAGE_URL } from './PjmBanner.constants';

interface PjmBannerProps {
    verticalId: number;
    verticalTitle: string;
}

const GRADIENT_BACKGROUND = 'linear-gradient(93.06deg, #FFFFFF 25.46%, #F3F6D4 85.28%, #D0E500 130.69%)';
const VERTICAL_GRADIENT_BACKGROUND = 'linear-gradient(142.94deg, #FFFFFF 29.74%, #F3F6D4 69.63%, #D0E500 99.92%)';

const TITLE_BY_CATEGORY = {
    [CATEGORIES_ID_MAPPER['programming-tech']]: 'development',
    [CATEGORIES_ID_MAPPER['online-marketing']]: 'marketing',
    [CATEGORIES_ID_MAPPER['graphics-design']]: 'design',
    [CATEGORIES_ID_MAPPER['video-animation']]: 'video',
    [CATEGORIES_ID_MAPPER['writing-translation']]: 'writing',
};

const source = 'vertical_page';

export const PjmBanner: React.FC<PjmBannerProps> = ({ verticalId, verticalTitle }) => {
    const { experience, abTests } = getContext();
    const pjmBannerTestGroup = `v${abTests[EXPERIMENTS.PJM_BANNER]}`;
    const querySource = experience?.isBusiness ? `pro_${source}` : source;
    const projectManagementPageURL = pathfinder(
        'content_pages_page',
        { page_slug: 'project-management' },
        { query: { source: `${querySource}_${pjmBannerTestGroup}` } }
    );

    const copiesPrefix = `${PJM_COPIES_PREFIX}.${pjmBannerTestGroup}`;

    useEffect(() => {
        BigQuery.sendBQEvent(
            {
                type: BQ_EVENTS.VIEWED_PROJECT_PARTNER_BANNER,
                pageName: 'vertical_page_perseus',
                group: 'user_impressions',
                actionType: 'impression',
            },
            {
                page: {
                    component: {
                        name: 'pjm_vertical_banner',
                        type: 'banner',
                    },
                    element: {
                        id: verticalId,
                        name: verticalTitle,
                    },
                },
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Card
            display="flex"
            borderRadius="xl"
            direction="row"
            alignItems="center"
            padding="6"
            rowGap="10"
            background={{ default: VERTICAL_GRADIENT_BACKGROUND, md: GRADIENT_BACKGROUND }}
            wrap="wrap"
        >
            <Stack
                direction="column"
                gap="6"
                paddingY={{ default: '0', sm: '2' }}
                paddingLeft={{ default: '0', md: '7' }}
                minWidth={0}
                flex="1 1 400px"
            >
                <Stack direction="column" gap="5">
                    <Heading fontWeight="bold" as="h4" size="h_lg">
                        <I18n
                            k={`${copiesPrefix}.title`}
                            params={{
                                category: TITLE_BY_CATEGORY[verticalId],
                            }}
                            templates={{ mark: (text) => <span style={{ color: '#00732E' }}>{text}</span> }}
                        />
                    </Heading>
                    <Text as="p" size="b_lg" paddingRight="6">
                        <I18n k={`${copiesPrefix}.description`} />
                    </Text>
                    <List gap="2" paddingLeft="0" paddingRight="6">
                        {['a', 'b', 'c'].map((point) => (
                            <ListItem key={point}>
                                <ListItemIcon>
                                    <CheckIcon size="md" color="grey_1100" />
                                </ListItemIcon>
                                <Text size="b_md">
                                    <I18n k={`${copiesPrefix}.points.${point}`} />
                                </Text>
                            </ListItem>
                        ))}
                    </List>
                </Stack>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="6"
                    justifyContent={{ default: 'center', sm: 'flexStart' }}
                    wrap={{ default: 'wrap', sm: 'nowrap' }}
                >
                    <Container flexGrow={{ default: 1, sm: 'initial' }}>
                        <Button size="lg" href={projectManagementPageURL} fullWidth={{ default: true, sm: false }}>
                            <I18n k={`${copiesPrefix}.cta`} />
                        </Button>
                    </Container>
                    <Stack direction="row" alignItems="center" gap="2">
                        <GuaranteeIcon size="lg" color="grey_1200" />
                        <Text whiteSpace="nowrap">
                            <I18n k={`${copiesPrefix}.money_back`} />
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                direction="column"
                gap="6"
                paddingY={{ default: '0', sm: '2' }}
                paddingX="4"
                alignItems="center"
                flexGrow="1"
                flexShrink="0"
                flexBasis={{ default: 0, sm: 'auto' }}
            >
                <Container
                    maxWidth="410px"
                    maxHeight="205px"
                    minWidth={{ sm: '410px', default: '280px' }}
                    minHeight={{ sm: '205px', default: '140px' }}
                    style={{ filter: 'drop-shadow(0px 44px 54px rgba(66, 72, 0, 0.2))' }}
                >
                    <Image width="100%" alt="banner image" src={PROJECT_PARTNER_IMAGE_URL} />
                </Container>
                <Text textAlign="center">
                    <I18n
                        k={`${copiesPrefix}.our_managers`}
                        templates={{ bold: (text) => <span style={{ fontWeight: 'bold' }}>{text}</span> }}
                    />
                </Text>
            </Stack>
        </Card>
    );
};
