import React from 'react';
import { Section } from '@fiverr-private/layout_components';
import { getContext } from '@fiverr-private/fiverr_context';
import { AgenciesBanner } from '../AgenciesBanner/AgenciesBanner';
import { createAgenciesBannerInfo } from '../utils';
import { EXPERIMENTS, ROLLOUTS } from '../../../lib/constants/experiments';
import { CATEGORIES_ID_MAPPER, PJM_BANNER_SUPPORTED_CATEGORY_IDS } from '../../../lib/constants/verticalsData';
import { ProjectPartnerBanner } from '../ProjectPartnerBanner/ProjectPartnerBanner';
import { PjmBanner } from '../PjmBanner/PjmBanner';

interface BannerSectionProps {
    verticalId: number;
    verticalTitle: string;
    rollouts: { [key: string]: boolean };
    currentBannerIndex: number;
}

const buildActiveBannerComponent = ({ currentBannerIndex, verticalId, verticalTitle, rollouts }) => {
    const agenciesBannerInfo = createAgenciesBannerInfo(verticalId, verticalTitle);
    const { experience, abTests } = getContext();
    let showProjectPartnerBanner = false;

    const showAgenciesBanner =
        rollouts[ROLLOUTS.AGENCIES_BANNERS_ROLLOUT] &&
        (verticalId === CATEGORIES_ID_MAPPER['programming-tech'] ||
            verticalId === CATEGORIES_ID_MAPPER['online-marketing'] ||
            verticalId === CATEGORIES_ID_MAPPER['graphics-design']) &&
        agenciesBannerInfo;

    if (rollouts[ROLLOUTS.PROJECT_PARTNER_BANNER_ROLLOUT] && PJM_BANNER_SUPPORTED_CATEGORY_IDS.includes(verticalId)) {
        if (experience?.isBusiness) {
            showProjectPartnerBanner = true;
        } else if (verticalId !== CATEGORIES_ID_MAPPER['writing-translation']) {
            showProjectPartnerBanner = rollouts[ROLLOUTS.PROJECT_PARTNER_BANNER_IN_MARKETPLACE_ROLLOUT];
        }
    }

    const bothBannersEnabled = showProjectPartnerBanner && showAgenciesBanner;

    const bannerComponent =
        !rollouts[ROLLOUTS.MUSTANGS_PJM_BANNER_VERTICAL_PAGE] ||
        !abTests[EXPERIMENTS.PJM_BANNER] ||
        abTests[EXPERIMENTS.PJM_BANNER] === 1 ? (
            <ProjectPartnerBanner verticalId={verticalId} verticalTitle={verticalTitle} />
        ) : (
            <PjmBanner verticalId={verticalId} verticalTitle={verticalTitle} />
        );

    if ((bothBannersEnabled && currentBannerIndex) || (!bothBannersEnabled && showAgenciesBanner)) {
        return <AgenciesBanner agenciesBannerInfo={agenciesBannerInfo} />;
    } else if ((bothBannersEnabled && !currentBannerIndex) || (!bothBannersEnabled && showProjectPartnerBanner)) {
        return bannerComponent;
    }
};

export const BannerSection: React.FC<BannerSectionProps> = ({
    verticalTitle,
    verticalId,
    rollouts,
    currentBannerIndex,
}) => {
    const component = buildActiveBannerComponent({
        currentBannerIndex,
        verticalId,
        verticalTitle,
        rollouts,
    });

    if (!component) {
        return null;
    }

    return (
        <Section paddingBottom="0" paddingTop="14">
            {component}
        </Section>
    );
};
